import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

//Declaring global variables. This variables will be used in all the app process
window.$apiEndpoint = 'https://api.failover.tribusdns.com/api/v2' //Backend
window.$fileserverEndpoint = 'https://fileserver.failover.tribusdns.com' //Fileserver
window.$fileserverEndpointCrop = 'https://fileserver.failover.tribusdns.com' //Fileserver End Poit for Crop Image




ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
