import { useHistory, Redirect, Link } from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import StockChart from "./shared/stockChart";
import { useForm } from 'react-hook-form'

import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  Logout,
  GiftIcon,
  UserGroup,
  ExclamationIcon,
  CogIcon,
  EyeOffIcon,
  EyeIcon,
  InformationCircleIcon,
  TrashIcon,
  CheckCircleIcon,
  CollectionIcon,
  PlusIcon,
  PencilIcon
} from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip';

import { func } from 'prop-types'

//Importo Demoni per le tabelle
import { useTable } from "react-table";
import Table from "./Table";
import Loader from "./Loader";






const navigation = [
  { name: 'Riepilogo', href: '/dashboard', icon: HomeIcon, current: true },
//  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
//  { name: 'Documents', href: '#', icon: InboxIcon, current: false },
//  { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const { parseCookies, destroyCookie } = require('nookies');


export default function Dashboard() {
  //Setto gli state
  const [allUsers, viewUsers] = useState([{}])
  const [single, viewSingle] = useState([{}])
  const [allLetture, viewLetture] = useState([{}])
  const [allLettureArea, viewLettureArea] = useState([{}])
  const [allLettureVelocita, viewLettureVelocita] = useState([{}])
  const [allLettureDistanza, viewLettureDistanza] = useState([{}])
  const [allLettureOre, viewLettureOre] = useState([{}])
  const [allLettureAreaTotale, viewLettureAreaTotale] = useState([{}])
  const [allLettureDistanzaTotale, viewLettureDistanzaTotale] = useState([{}])
  const [allLettureOreTotale, viewLettureOreTotale] = useState([{}])
  const [allLettureMaxVelocita, viewLettureMaxVelocita] = useState([{}])
  const [stato1, setStato1] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, formState: { errors: errors2 }, reset:reset2,  handleSubmit: handleSubmit2, } = useForm({mode: "onBlur"});

  const [showSucc, setSucc] = useState(false);
  const [showErr, setErr] = useState(false);
  const [showErr1, setErr1] = useState(false);

  const [showErr20, setErr20] = useState(false);
  const [toast, setToast] = useState(false);










  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const [allCostumers, viewCostumers] = useState('')
  const [idStato, setID] = useState('')

  const [allUsersC, viewUsersC] = useState('')
  const [allApp, viewApp] = useState('')
  const cookies = parseCookies()
  const [isLoggedOut, setLogout] = useState(false)
  const [isExpanded, toggleExpansion] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  let history = useHistory()
  const [showsetAllProfile, setAllProfile] = useState([{}]);
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)

  const indirzzi_email_loggato = cookies.email_merchant;
  const indirzzi_email_account = cookies.email;




  useEffect(() => {
    const expiration = cookies.expiration
    if(new Date(expiration) < new Date()) {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')
    }
  }, [setLogout])


  const logout = () => {
      localStorage.clear();
      destroyCookie()
      setLogout(true)
      history.push('/login')

  }

  const CALLlogout = () => {
    setOpen(true);
  }




  //Funzione che trasforma la stringa con la prima lettera maiuscola
  function capitalize(s)
  {
    //Trasformo tutto in minuscolo
    const n = s.toLowerCase();
    //Trasformo la prima lettera in maiuscolo
    return n[0].toUpperCase() + n.slice(1);
  }

  //Recupero Lista Utenti
  useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/users/fetchUtentiSistema/${indirzzi_email_loggato}`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          setAllProfile(info.data)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


   //Recupero Lista Dominio
   useEffect(() => {
    let mounted = true
    const fetch = async () => {
      const info = await axios.get(`${window.$apiEndpoint}/readings/fetchForRow`, {
        headers: {
          "x-access-token": cookies.token
        }
      });
      try {
        if(mounted){
          viewUsers(info.data)
          setLoading(true)


        }
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
    return () => {
      mounted = false
    }
  }, [])


  //Funzione Ricarica Tabella

  const Richiama = async () => {
    const info = await axios.get(`${window.$apiEndpoint}/readings/fetchForRow`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
     
        viewUsers(info.data)
        setLoading(true)

    } catch (e) {
      console.log(e)
    }
  }

  const RichiamaSingolo = async (id) => {
    const info = await axios.get(`${window.$apiEndpoint}/readings/fetchForRowS/${id}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {
     
        viewSingle(info.data)
        setID(info.data[0].id)
        
        setLoading2(true)

        //Apro Modale di Edit
        setOpen2(true)


    } catch (e) {
      console.log(e)
    }
  }


  const dataChart = {
    dataOdierna: new Date().toLocaleString(),
    stockFullName: "Lom_106_sn224",
    stockShortName: "IDENTIFICATIVO: 001",
    totaleArea: `${allLettureOreTotale.area} mq`,
    totaleDistanza: `${allLettureOreTotale.distanza} m`,
    totaleOre: `${allLettureOreTotale.ore} h`,
    maxVelocita: `${allLettureMaxVelocita} m/min`,
    chartData: {
      labels:  allLetture,
      data: allLettureArea,
      velocita: allLettureVelocita,
      distanza: allLettureDistanza,
      ore: allLettureOre,

    },
  };


  const columns = useMemo(
    () => [
      {
        Header: "Dominio",
        accessor: "nome_dominio",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">{cell.row.values.nome_dominio}</td>

        )
      },
      {
        Header: "Indirizzo Ip Primario",
        accessor: "ip_primario",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.ip_primario}</td>

        )
      },
      {
        Header: "Stato Indirizzo Ip Primario",
        accessor: "stato_ip_primario",
        Cell: cell => (
        
          cell.row.values.stato_ip_primario.split('/')[0] > 0 
          ?
          <>
          <ReactTooltip html={true}/>
           <td className="flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">
           <div className="rounded-md  p-4">
           <div className="flex">
           <div className="flex-shrink-0">
             <CheckCircleIcon  data-tip={`<strong>L'indirzzo ip risulta raggiungibile </strong> </br>Ultimo controllo: <strong>${cell.row.values.stato_ip_primario.split('/')[1]}</strong> </br>Indrizzo IP: <strong>${cell.row.values.stato_ip_primario.split('/')[2]}</strong> `} className="h-5 w-5 ml-2 text-green-500" aria-hidden="true" />
           </div>
           <div className="ml-3">
             <h3 className="text-sm font-medium text-green-800">Host Up</h3>
           </div>
             </div>
           </div>
         
         
         </td>
          </>
          :
          <>
          <ReactTooltip html={true}/>
          <td className="flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">
           <div className="rounded-md p-4">
           <div className="flex">
           <div className="flex-shrink-0">
             <CheckCircleIcon   data-tip={`<strong> L'indirzzo ip non risulta raggiungibile </strong> </br>Ultimo controllo: <strong>${cell.row.values.stato_ip_primario.split('/')[1]}</strong>  </br>Indrizzo IP: <strong>${cell.row.values.stato_ip_primario.split('/')[2]}</strong>`} className="h-5 w-5 ml-2 text-red-500" aria-hidden="true" />
           </div>
           <div className="ml-3">
             <h3 className="text-sm font-medium text-red-800">Host Down</h3>
           </div>
             </div>
           </div>
         
         
         </td>
          </>
           
         )
      },
      {
        Header: "Indirizzo Ip Secondario",
        accessor: "ip_secondario",
        Cell: cell => (
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cell.row.values.ip_secondario}</td>

        )
      },
      {
        Header: "Stato Indrizzo ip Secondario",
        accessor: "stato_ip_secondario",
        Cell: cell => (
        
         cell.row.values.stato_ip_secondario.split('/')[0] > 0 
         ?
         <>
         <ReactTooltip html={true}/>
          <td className="flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div className="rounded-md  p-4">
          <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon  data-tip={`<strong> L'indirzzo ip risulta raggiungibile </strong> </br>Ultimo controllo: <strong>${cell.row.values.stato_ip_secondario.split('/')[1]}</strong> </br>Indrizzo IP: <strong>${cell.row.values.stato_ip_secondario.split('/')[2]}</strong> `} className="h-5 w-5 ml-2 text-green-500" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">Host Up</h3>
          </div>
            </div>
          </div>
        
        
        </td>
         </>
         :
         <>
         <ReactTooltip html={true}/>
         <td className="flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div className="rounded-md  p-4">
          <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon   data-tip={`<strong> L'indirzzo ip non risulta raggiungibile</strong>  </br>Ultimo controllo: <strong>${cell.row.values.stato_ip_secondario.split('/')[1]}</strong> </br>Indrizzo IP: <strong>${cell.row.values.stato_ip_secondario.split('/')[2]}</strong> `} className="h-5 w-5 ml-2 text-red-500" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Host Down</h3>
          </div>
            </div>
          </div>
        
        
        </td>
         </>
          
        )
      },
      {
        Header: "Stato",
        accessor: "stato",
        Cell: cell => (
        
          cell.row.values.stato > 0 
          ?
          <>
          
           <td className="flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">
           <div className="rounded-md  p-4">
           <div className="flex">
           <div className="flex-shrink-0">
             <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500" aria-hidden="true" />
           </div>
           <div className="ml-3">
             <h3 className="text-sm font-medium text-green-800">FailOver Attivo</h3>
           </div>
             </div>
           </div>
         
         
         </td>
          </>
          :
          <>
          <td className="flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">
           <div className="rounded-md  p-4">
           <div className="flex">
           <div className="flex-shrink-0">
             <CheckCircleIcon className="h-5 w-5 ml-2 text-red-500" aria-hidden="true" />
           </div>
           <div className="ml-3">
             <h3 className="text-sm font-medium text-red-800">FailOver Non Attivo</h3>
           </div>
             </div>
           </div>
         
         
         </td>
          </>
           
         )
      },
      {
        Header: "Azioni",
        accessor: "azioni",
        Cell: cell => (
          <>
          <ReactTooltip html={true}/>
          <button data-tip="Modifica Dominio" onClick={() => RichiamaSingolo(cell.row.values.azioni)} class="bg-yellow-500 hover:bg-yellow-400  mr-1 text-white font-bold py-1 px-2 border-b-4 border-yellow-700 hover:border-yellow-500 rounded">
          <PencilIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>

          <button data-tip="Elimina Dominio" onClick={() => cancellaDominio(cell.row.values.azioni)} class="bg-red-500 hover:bg-red-400 text-white mr-2 font-bold py-1 px-2 border-b-4 border-red-700 hover:border-red-500 rounded">
          <TrashIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
          </>
        )
      },
    ],
    []
  );

  const getData = () => [

    allUsers

  ];
  const data = useMemo(() => getData(), []);


  function isValidDomainName(domain) {
    // Espressione regolare per il nome di dominio
    var domainRegex = /^(?:[-A-Za-z0-9]+\.)+[A-Za-z]{2,}$/;
  
    return domainRegex.test(domain);
  }

  function isValidIPAddress(ip) {
    // Espressione regolare per l'indirizzo IP
    var ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
  
    if (!ipRegex.test(ip)) {
      return false;
    }
  
    var octets = ip.split(".");
    for (var i = 0; i < octets.length; i++) {
      var octet = parseInt(octets[i], 10);
      if (octet < 0 || octet > 255) {
        return false;
      }
    }
  
    return true;
  }
  

  //Chiamata per cancellare il dominio

  const cancellaDominio = async (id) => {
    const info = await axios.delete(`${window.$apiEndpoint}/readings/delete/${id}`, {
      headers: {
        "x-access-token": cookies.token
      }
    });
    try {

      Richiama()

      setToast(true)
      setTimeout(() => {setToast(false)},3000)

    } catch (e) {
      console.log(e)
    }
  }


       //Chiamata post per inserimento nuovo dominio
       const onSubmit = data => {
        const dominio = data.dominio.toLowerCase().trim();
        const stato = data.stato;
        const ip_primario = data.ip_primario.trim();
        const ip_secondario = data.ip_secondario.trim();


        //Controllo che sia un vero dominio

        if (isValidDomainName(dominio)) {
        //Vero Proseguo controllo sugli indrizzi ip

            if (isValidIPAddress(ip_primario) && isValidIPAddress(ip_secondario)) {
            
            //Indirizzi ip Validi Proseguo ad inserire nel Database
            const addM = async () => {
              try {
                
                const add = await axios.post(`${window.$apiEndpoint}/readings/newDomain`, {
                  dominio: dominio,
                  stato: stato,
                  ip_primario: ip_primario,
                  ip_secondario: ip_secondario,
                  
        
              }, {
                  headers: {
                      'x-access-token' : cookies.token
                  }
              })
                setSucc(true);
                setTimeout(() => {setSucc(false)},5000)
                
                //Creato resetto, chiudo il modale e aggiono la tabella
                reset()
                setOpen1(false)
                Richiama()

              } catch (err) {
                setErr1(true)
                setTimeout(() => {setErr1(false)},6000)
              }
            }
            addM()
              
            } else {
            
            //Indrizzi Ip non validi stampo errore
            setErr(true)
            setTimeout(() => {setErr(false)},6000)

            }

        } else {
        //False dominio non valido stampo errore
        setErr20(true)
        setTimeout(() => {setErr20(false)},6000)
        }



      }


              //Chiamata per Edit del dominio
      const onSubmit2 = data => {
              const id = idStato;
              const stato = data.stato;
              const ip_primario = data.ip_primario.trim();
              const ip_secondario = data.ip_secondario.trim();
    
      
             //Proseguo controllo sugli indrizzi ip
      
                  if (isValidIPAddress(ip_primario) && isValidIPAddress(ip_secondario)) {
                  
                  //Indirizzi ip Validi Proseguo ad modificare nel Database
                  
                  const addM = async () => {
                    try {
                      
                      const add = await axios.post(`${window.$apiEndpoint}/readings/patch/${idStato}`, {
                        stato: stato,
                        ip_primario: ip_primario,
                        ip_secondario: ip_secondario,
                        
              
                    }, {
                        headers: {
                            'x-access-token' : cookies.token
                        }
                    })
                      setSucc(true);
                      setTimeout(() => {setSucc(false)},5000)
                      
                      //Creato resetto, chiudo il modale e aggiono la tabella
                      reset2()
                      setOpen2(false)
                      Richiama()
      
                    } catch (err) {
                      setErr1(true)
                      setTimeout(() => {setErr1(false)},6000)
                    }
                  }
                  addM()
                    
                  } else {
                  
                  //Indrizzi Ip non validi stampo errore
                  setErr(true)
                  setTimeout(() => {setErr(false)},6000)
      
                  }
      
           
      
      
      
            }
      

 //Funzione che resetta e chiude il modale
 const chiudi = () => {

  setLoading2(false)
  viewSingle([{}])
  setOpen2(false)
  reset2()
 }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-6 w-1/2"
                    src="https://fileserver.failover.tribusdns.com/logo.png"
                    alt="Logo"
                  />
                </div>
                <nav className="mt-58 px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src="https://fileserver.failover.tribusdns.com/logo_tri.png"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />

                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a onClick={() => CALLlogout()} >Logout</a></p>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-12">
                <img
                  className="w-40"
                  src="https://fileserver.failover.tribusdns.com/logo.png"
                  alt="Logo"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://fileserver.failover.tribusdns.com/logo_tri.png"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{capitalize(cookies.fname) + " " + capitalize(cookies.lname)}</p>
                    <ReactTooltip />

                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><spam>Ruolo: {capitalize(cookies.role) + "  "}</spam><a href="/profilo"><span data-tip="Impostazioni" className="flex flex-row text-red-300 group-hover:text-red-200"><CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Impostazioni</span></a></p>
                    <p data-tip="Esci" className="text-sm font-medium text-gray-100 group-hover:text-gray-200"><a  onClick={() => CALLlogout()} >Logout</a></p>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">

            <div className="max-w-12xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
              {/* Replace with your content */}



              <div className="max-w-12xl mx-auto px-4 sm:px-6 lg:px-8 mt-3" >
              <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">


              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">



              <h2 className="text-md mt-3 leading-6 font-bold text-gray-800">Domini Sotto Monitoraggio</h2>

              <a href="#" onClick={() => setOpen1(true)}>
              <button
                type="button"
                className="inline-flex items-center mt-2 px-4 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
               Inserisci Nuovo Dominio

              </button>
              </a>

              <div className="mt-3 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">




              {/* Tabella Esercenti */}

              {loading ? <Table columns={columns} data={allUsers} pagSize={10} /> : <Loader /> }


                      </div>
                    </div>
                  </div>
                </div>

               </div>







               {/*  Modale Logout */}
               <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Esegui il Logout
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sei sicuro di voler uscire?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => logout()}
                >
                  Esci
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
               </Transition.Root>
              {/* /End replace */}

              <Transition.Root show={open1} as={Fragment}>
                <Dialog
                  as="div"
                  static
                  className="fixed z-10 inset-0 overflow-y-auto"
                  initialFocus={cancelButtonRef}
                  open={open1}
                  onClose={setOpen1}
                >
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                      &#8203;
                    </span>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <PlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                              Inserisci nuovo
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Compila il seguente form per inserire un nuovo dominio sotto il monitoraggio per il failover
                              </p>
                            </div>

                          {/* Inizio Form */}

                          <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="dominio" className="block text-sm font-medium text-gray-700">
                          Nome Dominio
                        </label>
                        <input
                          type="text"
                          name="dominio"
                          id="dominio"
                          {...register("dominio", { required: true })}
                          autoComplete="dominio"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.dominio && <p>Il campo è obbligatorio</p>}
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="stato" className="block text-sm font-medium text-gray-700">
                          Stato FailOver
                        </label>
                        <div className="mt-1 ">
                          <select
                            id="stato"
                            name="stato"
                            autoComplete="stato"
                            {...register("stato", { required: true })}
                            className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          >
                            <option value="1">attivo</option>
                            <option value="0">disattivo</option>
                          </select>
                        </div>
                        {errors.stato && <p>Il campo è obbligatorio</p>}

                      </div>

                      <div className="col-start-1 col-end-7">
                        <label htmlFor="ip_primario" className="block text-sm font-medium text-gray-700">
                          Indrizzo Ip Primario
                        </label>
                        <input
                          type="text"
                          name="ip_primario"
                          id="ip_primario"
                          {...register("ip_primario", { required: true })}
                          autoComplete="ip_primario"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.ip_primario && <p>Il campo è obbligatorio</p>}
                        <small className="text-xs text-gray-400">Ip Master</small>

                      </div>

                      <div className="col-start-1 col-end-7">
                        <label htmlFor="ip_secondario" className="block text-sm font-medium text-gray-700">
                          Indrizzo Ip Secondario
                        </label>
                        <input
                          type="text"
                          name="ip_secondario"
                          id="ip_secondario"
                          {...register("ip_secondario", { required: true })}
                          autoComplete="ip_secondario"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.ip_secondario && <p>Il campo è obbligatorio</p>}
                        <small className="text-xs text-gray-400">Ip Slave</small>

                      </div>

                    </div>

                    {showErr20  ? <p className="text-xs text-red-400 mt-2">Il nome di dominio inserito non è valido</p> : null}
                    {showErr ? <p className="text-xs text-red-400 mt-2">Uno o più indirizzi IP non risultano Validi</p> : null}
                    {showErr1 ? <p className="text-xs text-red-400 mt-2">Attenzione! il dominio inserito risulta già presente nel database!</p> : null}

                    {showSucc ? <p className="text-xs text-green-400 mt-2">Dominio inserito con successo!</p> : null}

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                            type="submit"
                          >
                            Inserisci
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => setOpen1(false)}
                            ref={cancelButtonRef}
                          >
                            Annulla
                          </button>
                        </div>
                    </form>

                          {/* Fine Form */}


                          </div>
                        </div>
                     
                      </div>
                    </Transition.Child>
                  </div>
                </Dialog>
               </Transition.Root>


    {/* Modale per insert new Domain*/}





   {/* Toast di Notifica */}
 {toast ? <div class="absolute top-0 right-0 mt-4 mr-4 animate-pulse delay-75	">

          <div id="toast-success" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span class="sr-only">Check icon</span>
            </div>
            <div class="ml-3 text-sm font-normal">Dominio cancellato con successo.</div>
            <button type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
                <span class="sr-only">Close</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
            </button>
        </div>

  </div> : null}

  {/* Fine Toast di Notifica */}



  {/* Modale Edit Domain */}
  {loading2 ?   <Transition.Root show={open2} as={Fragment}>
                <Dialog
                  as="div"
                  static
                  className="fixed z-10 inset-0 overflow-y-auto"
                  initialFocus={cancelButtonRef}
                  open={open2}
                  onClose={setOpen2}
                >
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                      &#8203;
                    </span>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                     <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <PlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                              Modifica Dominio: {single[0].dominio} 
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Setta i nuovi parametri
                              </p>
                            </div>

                          {/* Inizio Form */}

                          <form className="mt-8" onSubmit={handleSubmit2(onSubmit2)}>
                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-12 sm:col-span-12">
                      <label htmlFor="stato" className="block text-sm font-medium text-gray-700">
                          Stato FailOver
                        </label>
                        <div className="mt-1 ">
                          <select
                            id="stato"
                            name="stato"
                            autoComplete="stato"
                            {...register2("stato", { required: true })}
                            className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          >
                            <option value="1">attivo</option>
                            <option value="0">disattivo</option>
                          </select>
                        </div>
                        {errors2.stato && <p>Il campo è obbligatorio</p>}

                      </div>

                      <div className="col-start-1 col-end-7">
                        <label htmlFor="ip_primario" className="block text-sm font-medium text-gray-700">
                          Indrizzo Ip Primario
                        </label>
                        <input
                          type="text"
                          name="ip_primario"
                          id="ip_primario"
                          placeholder={single[0].ip_primario}
                          {...register2("ip_primario", { required: true })}
                          autoComplete="ip_primario"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors2.ip_primario && <p>Il campo è obbligatorio</p>}
                        <small className="text-xs text-gray-400">Ip Master</small>

                      </div>

                      <div className="col-start-1 col-end-7">
                        <label htmlFor="ip_secondario" className="block text-sm font-medium text-gray-700">
                          Indrizzo Ip Secondario
                        </label>
                        <input
                          type="text"
                          name="ip_secondario"
                          id="ip_secondario"
                          placeholder={single[0].ip_secondario}
                          {...register2("ip_secondario", { required: true })}
                          autoComplete="ip_secondario"
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors2.ip_secondario && <p>Il campo è obbligatorio</p>}
                        <small className="text-xs text-gray-400">Ip Slave</small>

                      </div>

                    </div>

                    {showErr20  ? <p className="text-xs text-red-400 mt-2">Il nome di dominio inserito non è valido</p> : null}
                    {showErr ? <p className="text-xs text-red-400 mt-2">Uno o più indirizzi IP non risultano Validi</p> : null}
                    {showErr1 ? <p className="text-xs text-red-400 mt-2">Attenzione! il dominio inserito risulta già presente nel database!</p> : null}

                    {showSucc ? <p className="text-xs text-green-400 mt-2">Dominio inserito con successo!</p> : null}

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                            type="submit"
                          >
                            Modifica
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => chiudi()}
                            ref={cancelButtonRef}
                          >
                            Annulla
                          </button>
                        </div>
                    </form>

                          {/* Fine Form */}


                          </div>
                        </div>
                     
                      </div> 
                    </Transition.Child>
                  </div>
                </Dialog>
               </Transition.Root> : null}


    {/* Modale per edit Domain*/}





            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
